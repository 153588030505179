import React from "react";

// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper";
// images
/* import Img1 from "../assets/img/heroSlider/goldensun-giris1.jpg";
import Img2 from "../assets/img/heroSlider/goldensun-genel2.jpg";
import Img3 from "../assets/img/heroSlider/goldensunrainbowpool5.jpg"; */

/* import Img1 from "../assets/realPhotos/image1.jpeg";
import Img2 from "../assets/realPhotos/image2.jpeg";
import Img3 from "../assets/realPhotos/image3.jpeg";
import Img4 from "../assets/realPhotos/image4.jpeg";
import Img5 from "../assets/realPhotos/image5.jpeg";
import Img6 from "../assets/realPhotos/image6.jpeg";
import Img7 from "../assets/realPhotos/image7.jpeg";
import Img8 from "../assets/realPhotos/image8.jpeg";
import Img9 from "../assets/realPhotos/image9.jpeg"; */

//Last Photos
import Img1 from "../assets/lastPhotos/Golden Sun foto1.jpg";
import Img2 from "../assets/lastPhotos/Golden Sun foto2.jpg";
import Img3 from "../assets/lastPhotos/Golden Sun foto3.jpg";
import Img4 from "../assets/lastPhotos/Golden Sun foto4.JPG";
import Img5 from "../assets/lastPhotos/Golden Sun foto5.jpg";
import Img6 from "../assets/lastPhotos/Golden Sun foto6.jpg";
import Img7 from "../assets/lastPhotos/Golden Sun foto7.jpg";
import Img8 from "../assets/lastPhotos/Golden Sun foto8.JPG";
import Img9 from "../assets/lastPhotos/Golden Sun foto9.JPG";
import Img10 from "../assets/lastPhotos/Golden Sun foto10.JPG";
import Img11 from "../assets/lastPhotos/Golden Sun foto11.jpg";
import Img12 from "../assets/lastPhotos/Golden Sun foto12.jpg";
import Img13 from "../assets/lastPhotos/Golden Sun foto13.jpg";
import Img14 from "../assets/lastPhotos/Golden Sun foto14.jpg";
import Img15 from "../assets/lastPhotos/Golden Sun foto15.jpg";
import Img16 from "../assets/lastPhotos/Golden Sun foto16.jpg";
import Img17 from "../assets/lastPhotos/Golden-Sun-foto17.JPG";
import Img18 from "../assets/lastPhotos/Golden Sun foto18.jpg";
import Img19 from "../assets/lastPhotos/Golden Sun foto19.jpg";
import Img20 from "../assets/lastPhotos/Golden Sun foto20.jpg";
import Img21 from "../assets/lastPhotos/Golden Sun foto21.jpg";
import Img22 from "../assets/lastPhotos/Golden Sun foto22.jpg";
import Img23 from "../assets/lastPhotos/Golden Sun foto23.jpg";
import Img24 from "../assets/lastPhotos/Golden Sun foto24.jpg";
import Img25 from "../assets/lastPhotos/Golden Sun foto25.jpg";
import Img26 from "../assets/lastPhotos/Golden Sun foto26.jpg";
import Img27 from "../assets/lastPhotos/Golden Sun foto27.jpg";
import Img28 from "../assets/lastPhotos/Golden Sun foto28.jpg";
import Img29 from "../assets/lastPhotos/Golden Sun foto29.jpg";
import Img30 from "../assets/lastPhotos/Golden Sun party1.jpg";
import Img31 from "../assets/lastPhotos/Golden Sun party2.jpg";
import Img32 from "../assets/lastPhotos/Golden-Sun-party3.jpg";

/* import insta1 from "../assets/instagramPhotos/insta1.jpeg";
import insta2 from "../assets/instagramPhotos/insta2.jpeg";
import insta3 from "../assets/instagramPhotos/insta3.jpeg";
import insta4 from "../assets/instagramPhotos/insta4.jpeg";
import insta5 from "../assets/instagramPhotos/insta5.jpeg";
import insta6 from "../assets/instagramPhotos/insta6.jpeg"; */

const slides = [
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img1,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img2,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img3,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img4,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img5,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img6,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img7,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img8,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img9,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img10,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img11,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img12,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img13,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img14,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img15,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img16,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img17,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img18,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img19,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img20,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img21,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img22,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img23,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img24,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img25,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img26,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img27,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img28,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img29,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img30,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img31,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img32,
    btnText: "See our rooms",
  },
];

const GalleryHeroSlider = () => {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#87674F",
        "--swiper-navigation-color": "#87674F",
      }}
      modules={[EffectFade, Autoplay, Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation
      effect={"fade"}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      className="heroSlider h-[600px] lg:h-[100vh]"
    >
      {slides.map((slide, index) => {
        // destructure slide
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="absolute top-0 w-full h-full">
              <img className="object-cover h-full w-full" src={bg} alt="" />
            </div>
            {/* overLay */}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default GalleryHeroSlider;
