import React, { createContext, useEffect, useState } from "react";

//data
import { restaurantData } from "../data";

// create context

export const RestaurantContext = createContext();

const RestaurantProvider = ({ children }) => {
  const [Restaurants, setRestaurants] = useState(restaurantData);
  
  return (
    <RestaurantContext.Provider
      value={{ Restaurants }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export default RestaurantProvider;
