import React, { useContext } from "react";

// scroll top component
import ScrollToTop from "../components/ScrollToTop";
// context
import { ContactContext } from "../context/ContactContext";
// icons
import { FaCheck, FaCity, FaHospitalAlt } from "react-icons/fa";
import { GiCommercialAirplane, GiModernCity } from "react-icons/gi";
import { MdEmojiTransportation } from "react-icons/md";

const ContactDetails = () => {
  const { Contact } = useContext(ContactContext);

  // get restaurant
  const ContactInfo = Contact.find((ContactInfo) => {
    return ContactInfo.id === 1;
  });

  // destructure restaurant
  const { name, description, facilities, imageLg, price, image } = ContactInfo;

  return (
    <section>
      <ScrollToTop />
      {/* banner */}
      <div
        style={{ backgroundImage: `url(${imageLg})` }}
        className="bg-room bg-cover bg-center h-[560px] relative flex justify-center items-center"
      >
        {/* overlay */}
        <div className="absolute w-full h-full bg-black/70"></div>
        {/* title */}
        <h1 className="text-6xl text-white z-20 font-primary text-center">
          {name} Us
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row h-full py-24">
          {/* Left */}
          <div className="w-full h-full lg:w-[60%] px-6">
            <h2 className="h2">{name}</h2>
            <p className="mb-8">{description}</p>
            <img className="mb-8" src={image} alt="" />

            {/* informations */}
            <div className="mt-12">
              <h3 className="h3">Information</h3>
              <p className="mb-6">BELDİBİ - ANTALYA / TURKEY</p>
              <ul className="flex flex-col gap-y-4">
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Telefon : +90 242 824 80 99 - 824 80 70
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Faks : +90 242 824 82 71
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Gsm : +90 530 053 07 24
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Mail : info@otelgoldensun.com
                </li>
              </ul>
            </div>
          </div>
          {/* right */}
          <div className="w-full h-full lg:w-[40%]">
            {/* Google Maps */}

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6604.54802117488!2d30.561053000000005!3d36.71855!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c3bf178803aa33%3A0xa1fa02bc9aee11d4!2sHotel%20Golden%20Sun!5e1!3m2!1str!2suk!4v1692109424549!5m2!1str!2suk"
              width="350vw"
              height="350"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            {/* informations */}
            <div className="mt-12">
              <h3 className="h3">Location</h3>
              <p className="mb-6">Distances to social places.</p>
              <ul className="flex flex-col gap-y-4">
                <li className="flex items-center gap-x-4">
                  <GiCommercialAirplane className="text-accent" />
                  Antalya Airport (45km)
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCity className="text-accent" />
                  Nearest Settlement Kemer (15km)
                </li>
                <li className="flex items-center gap-x-4">
                  <GiModernCity className="text-accent" />
                  Nearest City Center Antalya (20km)
                </li>
                <li className="flex items-center gap-x-4">
                  <FaHospitalAlt className="text-accent" />
                  Nearest Hospital Kemer (15km)
                </li>
                <li className="flex items-center gap-x-4">
                  <MdEmojiTransportation className="text-accent" />
                  Transportation with minibus every 15 minutes to Kemer and
                  Antalya
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails;
