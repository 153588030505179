import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import RoomProvider from "./context/RoomContext";
import RestaurantProvider from "./context/RestaurantContext";
import ContactProvider from "./context/ContactContext";
import GalleryProvider from "./context/GalleryContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GalleryProvider>
    <ContactProvider>
      <RestaurantProvider>
        <RoomProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </RoomProvider>
      </RestaurantProvider>
    </ContactProvider>
  </GalleryProvider>
);
