import React from "react";

// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper";

//Last Photos
import RoomD1 from "../assets/img/rooms/oda/dbl-1.1.jpg";
import RoomD2 from "../assets/img/rooms/oda/dbl-1.2.jpg";
import RoomD3 from "../assets/img/rooms/oda/dbl-1.3.jpg";
import RoomD4 from "../assets/img/rooms/oda/dbl-1.4.jpg";
import RoomD5 from "../assets/img/rooms/oda/dbl-2.1.jpg";
import RoomD6 from "../assets/img/rooms/oda/dbl-2.2.jpg";
import RoomD7 from "../assets/img/rooms/oda/dbl-2.3.jpg";
import RoomD8 from "../assets/img/rooms/oda/dbl-2.4.jpg";

const slides = [
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD1,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD2,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD3,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD4,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD5,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD6,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD7,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomD8,
    btnText: "See our rooms",
  },
];

const RoomDoubleHeroSlider = () => {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#87674F",
        "--swiper-navigation-color": "#87674F",
      }}
      modules={[EffectFade, Autoplay, Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation
      effect={"fade"}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      className="heroSlider h-[300px] lg:h-[50vh]"
    >
      {slides.map((slide, index) => {
        // destructure slide
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="absolute top-0 w-full h-full">
              <img className="object-cover h-full w-full" src={bg} alt="" />
            </div>
            {/* overLay */}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default RoomDoubleHeroSlider;
