import React from "react";

// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper";
// images

import bgVideo from "../assets/videos/Golden-Sun-Tanıtım.mp4";

/* const slides = [
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img1,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img2,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img3,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img4,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img5,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img6,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img7,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img8,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Img9,
    btnText: "See our rooms",
  },
]; */

const slides = [
  {
    title: "Values of the past meet our new face",
    bg: bgVideo,
    btnText: "See our rooms",
  },
];

const HeroSlider = () => {
  return (
    <Swiper
      /* style={{
        "--swiper-pagination-color": "#87674F",
        "--swiper-navigation-color": "#87674F",
      }}
      modules={[EffectFade, Autoplay, Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation
      effect={"fade"}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }} */
      className="heroSlider h-[600px] lg:h-[100vh]"
    >
      {slides.map((slide, index) => {
        // destructure slide
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="z-20 text-white text-center">
              <div
                className="uppercase font-tertiary tracking-[6px] mb-5"
                style={{ marginTop: "50px" }}
              >
                Just Enjoy and relax
              </div>
              <h1 className="text-[32px] font-primary uppercase tracking-[2px] max-w-[920px] lg:text-[68px] leading-tight mb-6">
                {title}
              </h1>

              {/* <div>
                <img className="w-[250px] mx-auto" src={since1991} />
                  </div> */}

              <a href="/#rooms">
                <button className="btn btn-lg btn-primary mx-auto">
                  {btnText}
                </button>
              </a>
            </div>
            <div className="absolute top-0 w-full h-full">
              <video
                className="object-cover h-full w-full"
                src={bgVideo}
                autoPlay
                loop
                muted
              />
            </div>
            {/* overLay */}
            <div className="absolute w-full h-full bg-black/70"></div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeroSlider;
