import React, { createContext, useEffect, useState } from "react";

//data
import { contactData } from "../data";

// create context

export const ContactContext = createContext();

const ContactProvider = ({ children }) => {
  const [Contact, setContact] = useState(contactData);
  
  return (
    <ContactContext.Provider
      value={{ Contact }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export default ContactProvider;
