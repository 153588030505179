import React, { useContext } from "react";

// scroll top component
import ScrollToTop from "../components/ScrollToTop";
// context
import { GalleryContext } from "../context/GalleryContext";
// icons
import { FaCheck } from "react-icons/fa";

import GalleryHeroSlider from "../components/GalleryHeroSlider";
import VideoGallery from "../components/VideoGallery";

const Gallery = () => {
  const { Gallery } = useContext(GalleryContext);

  // get restaurant
  const GalleryInfo = Gallery.find((GalleryInfo) => {
    return GalleryInfo.id === 1;
  });

  // destructure restaurant
  const { name, description, facilities, imageLg, price } = GalleryInfo;

  return (
    <section>
      <ScrollToTop />
      {/* banner */}
      <div
        style={{ backgroundImage: `url(${imageLg})` }}
        className="bg-room bg-cover bg-center h-[560px] relative flex justify-center items-center"
      >
        {/* overlay */}
        <div className="absolute w-full h-full bg-black/70"></div>
        {/* title */}
        <h1 className="text-6xl text-white z-20 font-primary text-center">
          {name}
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row h-full py-14">
          {/* Left */}
          <div className="w-full h-full lg:w-[100%] px-6">
            <h2 className="h2">{name}</h2>
            <p className="mb-8">{description}</p>
            <GalleryHeroSlider />

            {/* <img className="mb-8" src={imageLg} alt="" /> */}
            {/* facilities */}
            {/* <div className="mt-12">
              <h3 className="h3 mb-3">Facilities</h3>
              <p className="mb-12">
                We offer many facilities in Hotel Golden Sun
              </p>
              <div className="grid grid-cols-3 gap-6 mb-12">
                {facilities.map((item, index) => {
                  // destructure item
                  const { name, icon } = item;
                  return (
                    <div
                      className="flex items-center gap-x-3 flex-1"
                      key={index}
                    >
                      <div className="text-3xl text-accent">{icon}</div>
                      <div className="text-base">{name}</div>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
          {/* right */}
          {/* <div className="w-full h-full lg:w-[40%]">
            <div>
              <h3 className="h3">Rules</h3>
              <p className="mb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas,
                nihil.
              </p>
              <ul className="flex flex-col gap-y-4">
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Check-in: 3:00 PM - 9:00 PM
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Check-out: 10:30 AM
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  No Pets
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  No Smoking
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="flex flex-col lg:flex-row h-full pb-24">
          {/* Left */}
          <div className="w-full h-full lg:w-[100%] px-6">
            <h2 className="h2">Videos</h2>
            <p className="mb-8">
              You can view the video gallery showing the unique beauty of Hotel
              Golden Sun.
            </p>
            <VideoGallery />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
