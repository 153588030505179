import React from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";

import Home from "./pages/Home";
import RoomDetails from "./pages/RoomDetails";
import RestaurantDetails from "./pages/RestaurantDetails";
import Gallery from "./pages/Gallery";
import NotFoundPage from "./pages/NotFoundPage";
import Rooms from "./components/Rooms";

import {
  createHashRouter,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";

const router = createHashRouter([
  {
    path: "/",
    element: <LayoutComponent />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "rooms",
        element: <Rooms />,
      },
      {
        path: "room/:id",
        element: <RoomDetails />,
      },
      {
        path: "restaurant",
        element: <RestaurantDetails />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);

function LayoutComponent() {
  return (
    <>
      <Header />
      <main>
        <Outlet /> {/* Nested routes render here */}
      </main>
      <Footer />
    </>
  );
}

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
