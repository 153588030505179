import React from "react";

// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper";

//Last Photos
import RoomT1 from "../assets/img/rooms/oda/trp-1.1.jpg";
import RoomT2 from "../assets/img/rooms/oda/trp-1.2.jpg";
import RoomT3 from "../assets/img/rooms/oda/trp-1.3.jpg";
import RoomT4 from "../assets/img/rooms/oda/trp-1.4.jpg";
import RoomT5 from "../assets/img/rooms/oda/trp-1.5.jpg";
import RoomT6 from "../assets/img/rooms/oda/trp-1.6.jpg";
import RoomT7 from "../assets/img/rooms/oda/trp-1.7.jpg";
import RoomT8 from "../assets/img/rooms/oda/trp-1.8.jpg";
import RoomT9 from "../assets/img/rooms/oda/trp-1.9.jpg";

import RoomT10 from "../assets/img/rooms/oda/trp-2.1.jpg";
import RoomT11 from "../assets/img/rooms/oda/trp-2.2.jpg";
import RoomT12 from "../assets/img/rooms/oda/trp-2.3.jpg";
import RoomT13 from "../assets/img/rooms/oda/trp-2.4.jpg";
import RoomT14 from "../assets/img/rooms/oda/trp-2.5.jpg";
import RoomT15 from "../assets/img/rooms/oda/trp-2.6.jpg";
import RoomT16 from "../assets/img/rooms/oda/trp-2.7.jpg";
import RoomT17 from "../assets/img/rooms/oda/trp-2.8.jpg";
import RoomT18 from "../assets/img/rooms/oda/trp-2.9.jpg";

const slides = [
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT1,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT2,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT3,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT4,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT5,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT6,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT7,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT8,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT9,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT10,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT11,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT12,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT13,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT14,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT15,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT16,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT17,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: RoomT18,
    btnText: "See our rooms",
  },
];

const RoomTripleHeroSlider = () => {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#87674F",
        "--swiper-navigation-color": "#87674F",
      }}
      modules={[EffectFade, Autoplay, Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation
      effect={"fade"}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      className="heroSlider h-[300px] lg:h-[50vh]"
    >
      {slides.map((slide, index) => {
        // destructure slide
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="absolute top-0 w-full h-full">
              <img className="object-cover h-full w-full" src={bg} alt="" />
            </div>
            {/* overLay */}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default RoomTripleHeroSlider;
