// rooms images
import Room1Img from "./assets/img/rooms/room1.jpg";
import RoomDouble from "./assets/img/rooms/oda/dbl-1.2.jpg";

import Room1ImgLg from "./assets/img/rooms/1-lg.png";

import Room5Img from "./assets/img/rooms/room5.jpg";
import RoomTriple from "./assets/img/rooms/oda/trp-2.2.jpg";

import Room5ImgLg from "./assets/img/rooms/5-lg.png";

// restaurant images
import Restaurant1Img from "./assets/img/restaurant/restaurant.jpg";
import RestaurantInside from "./assets/img/restaurant/restaurantInside.jpg";
import RestaurantOutside from "./assets/img/restaurant/restaurantOutside.jpg";

// contact images
import ContactImg from "./assets/img/contact/contact.jpg";
import ContactImg2 from "./assets/img/contact/contact2.jpg";

import newContactBgSm from "./assets/img/contact/newContactBgSm.jpg";
import newContactBgGiris from "./assets/img/contact/newContactBgGiris.jpg";

import contactLastBig from "./assets/lastPhotos/Golden-Sun-foto17.JPG";
import contactLastSm from "./assets/lastPhotos/Golden Sun foto8.JPG";

// gallery images
import GalleryImg from "./assets/realPhotos/image1.jpeg";
import GalleryImg2 from "./assets/realPhotos/image2.jpeg";
import GalleryImg3 from "./assets/realPhotos/image3.jpeg";
import GalleryImg4 from "./assets/realPhotos/image4.jpeg";
import GalleryImg5 from "./assets/realPhotos/image5.jpeg";
import GalleryImg6 from "./assets/realPhotos/image6.jpeg";
import GalleryImg7 from "./assets/realPhotos/image7.jpeg";
import GalleryImg8 from "./assets/realPhotos/image8.jpeg";
import GalleryImg9 from "./assets/realPhotos/image9.jpeg";

// import icons
import {
  FaWifi,
  FaCoffee,
  FaBath,
  FaParking,
  FaSwimmingPool,
  FaHotdog,
  FaStopwatch,
  FaCocktail,
} from "react-icons/fa";

export const galleryData = [
  {
    id: 1,
    name: "Gallery",
    description:
      "You can view the photo gallery showing the unique beauty of Hotel Golden Sun. ",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },
      { name: "Swimming Pool", icon: <FaSwimmingPool /> },
      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "GYM", icon: <FaStopwatch /> },
      { name: "Drinks", icon: <FaCocktail /> },
    ],
    size: 30,
    maxPerson: 1,
    price: 115,
    image: GalleryImg,
    imageLg: GalleryImg,
  },
];

export const restaurantData = [
  {
    id: 1,
    name: "Restaurant",
    description:
      "An open buffet restaurant offering a wide range of flavours from Turkish cuisine with a pool bar waiting for our guests.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },
      { name: "Swimming Pool", icon: <FaSwimmingPool /> },
      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "GYM", icon: <FaStopwatch /> },
      { name: "Drinks", icon: <FaCocktail /> },
    ],
    size: 30,
    maxPerson: 1,
    price: 115,
    imageSm: RestaurantOutside,
    imageLg: RestaurantInside,
  },
];

export const contactData = [
  {
    id: 1,
    name: "Contact",
    description:
      "Hotel Golden Sun is set amongst pine on the Mediterranean coast. Beldibi's immense sandy beaches and magnificent landscape make it a destination full of natural beauty. It is located in the middle of historical, cultural, and natural beauties with proximity to the Taurus Mountains, national parks, and archaeological sites. Hotel Golden Sun does not only offer a luxury vacation for you and your loved ones, but it also provides a unique vacation culture and extraordinary rituals thanks to its eye-catching blue sea, fascinating nature, beach, chefs and delicacies creating a gastronomic culture, sparkling entertainment, a game culture that turns learning into discovery, and a sports culture that turns movement into motivation. Offering a holiday culture that you will be a part of, Hotel Golden Sun is waiting for you at 20 kilometers from Antalya city center, 45 kilometers from Antalya International Airport and only 2 kilometers from Beldibi city center.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },
      { name: "Swimming Pool", icon: <FaSwimmingPool /> },
      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "GYM", icon: <FaStopwatch /> },
      { name: "Drinks", icon: <FaCocktail /> },
    ],
    size: 30,
    maxPerson: 1,
    price: 115,
    image: contactLastSm,
    imageLg: contactLastBig,
  },
];

export const roomData = [
  {
    id: 1,
    name: "Standart Room",
    description: "2 person luxury room.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },
      { name: "Swimming Pool", icon: <FaSwimmingPool /> },
      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "GYM", icon: <FaStopwatch /> },
      { name: "Drinks", icon: <FaCocktail /> },
    ],
    size: 30,
    maxPerson: 2,
    price: 115,
    image: RoomDouble,
    imageLg: Room1ImgLg,
  },
  {
    id: 2,
    name: "Standart Room",
    description: "3 person luxury room.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },
      { name: "Swimming Pool", icon: <FaSwimmingPool /> },
      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "GYM", icon: <FaStopwatch /> },
      { name: "Drinks", icon: <FaCocktail /> },
    ],
    size: 70,
    maxPerson: 3,
    price: 220,
    image: RoomTriple,
    imageLg: Room5ImgLg,
  },
];
