import React, { useState, useEffect } from "react";

// logo
import LogoWhite from "../assets/img/golden-yatay-white-new.svg";
import LogoDark from "../assets/img/golden-yatay-white-dark.svg";
//import LogoDark2 from "../assets/img/golden-yatay.svg";
import since1991 from "../assets/editedPhotos/since1991-new-pantone.svg";
import since1991dark from "../assets/editedPhotos/since1991-dark-pantone.svg";

const Header = () => {
  const [header, setHeader] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    });
  });
  return (
    <header
      className={`${
        header ? "bg-white py-6 shadow-lg" : "bg-transparent py-8"
      } fixed z-50 w-full transition-all duration-500`}
    >
      <div className="container mx-auto flex flex-col items-center gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0 ">
        {/* logo */}
        <a href="/">
          {header ? (
            <img className="w-[250px]" src={LogoDark} />
          ) : (
            <img className="w-[250px]" src={LogoWhite} />
          )}
        </a>
        {/* nav */}
        <nav
          className={`${
            header ? "text-primary" : "text-white"
          } flex gap-x-4 font-tertiary tracking-[3px] text-[15px] items-center uppercase lg:gap-x-8`}
        >
          <a href="/" className="hover:text-accent transition">
            Home
          </a>
          <a href="/#rooms" className="hover:text-accent transition">
            Rooms
          </a>
          <a href="/#restaurant" className="hover:text-accent transition">
            Restaurant
          </a>
          <a href="/#gallery" className="hover:text-accent transition">
            Gallery
          </a>
          <a href="/#contact" className="hover:text-accent transition">
            Contact
          </a>
        </nav>
        <span>
          {header ? (
            <img className="w-[100px]" src={since1991dark} />
          ) : (
            <img className="w-[100px]" src={since1991} />
          )}
        </span>
      </div>
    </header>
  );
};

export default Header;
