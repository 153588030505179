import React, { createContext, useEffect, useState } from "react";

//data
import { galleryData } from "../data";

// create context

export const GalleryContext = createContext();

const GalleryProvider = ({ children }) => {
  const [Gallery, setGallery] = useState(galleryData);

  return (
    <GalleryContext.Provider value={{ Gallery }}>
      {children}
    </GalleryContext.Provider>
  );
};

export default GalleryProvider;
