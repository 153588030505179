import React from "react";
// logo
import LogoWhite from "../assets/img/golden-yatay-white-new.svg";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";

import since1991 from "../assets/editedPhotos/since1991-new-pantone.svg";
import years33 from "../assets/editedPhotos/33years.svg";

const Footer = () => {
  return (
    <footer className="bg-primary py-12">
      <div className="container mx-auto text-white flex items-center justify-between flex-col md:flex-row md:items-start gap-10">
        {/* Logo */}

        <div>
          <a href="/">
            <img
              src={LogoWhite}
              alt=""
              style={{ width: "200px", height: "100px" }}
            />
          </a>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <a href="https://www.instagram.com/hotelgoldensun/" target="_blank">
            <FaInstagram style={{ width: "44px", height: "100px" }} />
          </a>
          <a
            href="https://www.facebook.com/hotelgoldensunantalya/?locale=tr_TR"
            target="_blank"
            style={{ marginLeft: "20px" }}
          >
            <AiOutlineFacebook style={{ width: "50px", height: "100px" }} />
          </a>
        </div>

        {/*Copyright*/}
        <p className="font-montserrat" style={{ display: "flex" }}>
          <img
            /* className="w-[100px]" */
            style={{ width: "100px", height: "100px", margin: 0, padding: 0 }}
            src={since1991}
          />
          {/* <img
            style={{ width: "150px", height: "100px", margin: 0, padding: 0 }}
            src={years33}
          /> */}

          {/* All rights reserved. &copy; 2023 <br />
          <span style={{ display: "flex", justifyContent: "center" }}>
            SINCE 1991 - 33 YEARS
          </span> */}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
