import React, { useContext } from "react";

// scroll top component
import ScrollToTop from "../components/ScrollToTop";
// context
import { RestaurantContext } from "../context/RestaurantContext";
// icons
import { FaCheck } from "react-icons/fa";

const RestaurantDetails = () => {
  const { Restaurants } = useContext(RestaurantContext);

  // get restaurant
  const Restaurant = Restaurants.find((Restaurant) => {
    return Restaurant.id === 1;
  });

  // destructure restaurant
  const { name, description, facilities, imageSm, imageLg, price } = Restaurant;

  return (
    <section>
      <ScrollToTop />
      {/* banner */}
      <div
        style={{ backgroundImage: `url(${imageLg})` }}
        className="bg-room bg-cover bg-center h-[560px] relative flex justify-center items-center"
      >
        {/* overlay */}
        <div className="absolute w-full h-full bg-black/70"></div>
        {/* title */}
        <h1 className="text-6xl text-white z-20 font-primary text-center">
          {name} Details
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row h-full py-24">
          {/* Left */}
          <div className="w-full h-full lg:w-[60%] px-6">
            <h2 className="h2">{name} & Bar</h2>
            <p className="mb-8">{description}</p>
            <img className="mb-8" src={imageSm} alt="" />
            <img className="mb-8" src={imageLg} alt="" />

            {/* facilities */}
            <div className="mt-12">
              <h3 className="h3 mb-3">Restaurant Facilities</h3>
              <p className="mb-12">
                It is impossible to go hungry or thirsty at a Hotel Golden Sun.
                A wealth of restaurants and bars awaits anyone looking for a
                quick bite between activities, an intimate dinner for two or a
                cocktail to sip as the sun goes down on another adventure-filled
                day.
              </p>
              {/* grid */}
              {/* <div className="grid grid-cols-3 gap-6 mb-12">
                {facilities.map((item, index) => {
                  // destructure item
                  const { name, icon } = item;
                  return (
                    <div
                      className="flex items-center gap-x-3 flex-1"
                      key={index}
                    >
                      <div className="text-3xl text-accent">{icon}</div>
                      <div className="text-base">{name}</div>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
          {/* right */}
          <div className="w-full h-full lg:w-[40%]">
            {/* reservation 
            <div className="py-8 px-6 bg-accent/20 mb-12">
              <div className="flex flex-col space-y-4 mb-4">
                <h3>Your Reservation</h3>
                <div className="h-[60px]">
                  <CheckIn />
                </div>
                <div className="h-[60px]">
                  <CheckOut />
                </div>
                <div className="h-[60px]">
                  <AdultsDropdown />
                </div>
                <div className="h-[60px]">
                  <KidsDropdown />
                </div>
              </div>
              <button className="btn btn-lg btn-primary w-full">
                book now for ${price}
              </button>
            </div> */}
            {/* rules */}
            <div>
              <h3 className="h3">Restaurant Rules</h3>
              {/* <p className="mb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas,
                nihil.
              </p> */}
              <ul className="flex flex-col gap-y-4">
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Check-in: 12:00 PM - 6:00 PM
                </li>
                {/* <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Check-out: 10:30 AM
                </li> */}
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  No Pets
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  No Smoking
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RestaurantDetails;
