import React from "react";

// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper";

//Last Videos
import Video1 from "../assets/videos/GoldenTanıtımVideo1.mp4";
import Video2 from "../assets/videos/GoldenTanıtımVideo2.mp4";

import PosterImage from "../assets/lastPhotos/Golden Sun foto1.jpg";

const slides = [
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Video1,
    btnText: "See our rooms",
  },
  {
    title: "Your Luxury Hotel For Vacation",
    bg: Video2,
    btnText: "See our rooms",
  },
];

const VideoGallery = () => {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#87674F",
        "--swiper-navigation-color": "#87674F",
      }}
      modules={[EffectFade, Autoplay, Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation
      effect={"fade"}
      loop={true}
      /* autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }} */
      className="heroSlider h-[600px] lg:h-[100vh]"
    >
      {slides.map((slide, index) => {
        // destructure slide
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="absolute top-0 w-full h-full">
              <video
                className="object-cover h-full w-full"
                src={bg}
                controls="controls"
                preload="none"
                onClick={(event) => {
                  event.target.paused
                    ? event.target.play()
                    : event.target.pause();
                }}
                poster={PosterImage}
                /* autoPlay */
                /* loop */
                /* muted */
              />
            </div>
            {/* overLay */}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default VideoGallery;
