import React, { useContext } from "react";

// context
import { RoomContext } from "../context/RoomContext";

// components
import Room from "../components/Room";

// Loader
import { SpinnerDotted } from "spinners-react";

import mamut from "../assets/img/backgroundPhotos/mamut.svg";
import yaprak from "../assets/img/backgroundPhotos/yaprak.svg";

const Rooms = () => {
  const { rooms, loading } = useContext(RoomContext);

  return (
    <section id="rooms" /* className="py-24" */>
      <div
        style={{ backgroundImage: `url(${yaprak})` }}
        className="bg-room bg-cover bg-center sm:h-screen h-full relative flex justify-center items-center"
      >
        {/*overlay & spinner*/}
        {loading && (
          <div className="h-screen fixed bottom-0 bg-black/90 w-full z-50 flex justify-center items-center">
            <SpinnerDotted color="white" />
          </div>
        )}
        <div className="container mx-auto lg:px-0">
          <div className="text-center">
            <div className="font-qwenzy uppercase text-[15px] tracking-[6x] sm:visible invisible">
              Hotel Golden Sun
            </div>
            <h2 className="font-primary text-[45px] mb-4">Room & Suites</h2>
          </div>
          {/*grid*/}
          <div className="grid grid-cols-1 max-w-sm mx-auto gap-[30px] lg:grid-cols-2 lg:max-w-none lg-mx-0">
            {rooms.map((room) => {
              return <Room room={room} key={room.id} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rooms;
